import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/pmaxh/Documents/node-projects/website/src/components/page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`My web app projects:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/web-apps/blar"
        }}>{`URL shortener`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/web-apps/tierlist"
        }}>{`tier list maker`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/web-apps/wdig"
        }}>{`URL lookup site`}</a>{` (find where a shortened URL goes!)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/web-apps/armorcalc"
        }}>{`Minecraft Armor Stat Calculator`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/web-apps/cylinder-music"
        }}>{`Apple Music Library Visualizer`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/web-apps/votecats"
        }}>{`social image site`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/web-apps/doppelganger"
        }}>{`username lookup site`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/web-apps/sand"
        }}>{`sliding square simulation`}</a>{` (try this on your phone!)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/web-apps/oeis-lookup"
        }}>{`integer sequence lookup site`}</a>{` (query the OEIS!)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      